/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//ngx data table css 
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
ion-accordion-group{
    margin-right: 20px;
    border-radius: 10px;
    --background: transparent;

    ion-accordion {
        margin-left: 10px;
        border-radius: 10px;
        background: transparent;
        .ion-accordion-toggle-icon {
            font-size: 20px;
            color: white;
        }

        .div-text{
            background: white;
        }
        .div-item{
            --background: white;
            color: black;
            font-size: 14px;
            
        }
        .div-item:hover{
            --background: rgb(248, 248, 248);
            color: black;
            border-radius: 10px;
        }
    }
    
}
.modal{
    --border-radius: 10px;
    --width: 70%;
    --height: 90%;
}



